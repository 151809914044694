@charset "UTF-8";
@use "settings" as s;

html,body{
	height: 100%;
}

.en{
	font-family: cormorant-garamond, serif;
	font-weight: 500;
	font-style: normal;
	text-align: left;
}
.head{
	font-family: a-otf-futo-go-b101-pr6n, sans-serif;
	font-weight: 500;
	font-style: normal;
	text-align: left;
}

.header{
	padding: 22px 0 25px 0;
	@include s.mq(w767){
		height: 76px;
		padding: 11px 16px;
	}
	&__logo{
		width: 81px;
		height: auto;
		margin: auto;
		@include s.mq(w767){
			width: 45px;
		}
	}
}
.page-reservation{
	position: fixed;
	//top: 93px;
	top: 213px;
	right: 0;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 41px;
	height: 112px;
	border-radius: 11px 0 0 11px;
	background: #000;
	color: #fff;
	writing-mode: vertical-rl;
	font-size: 1.4rem;
	font-weight: 700;
	letter-spacing: .2em;
	transition: background .2s;
	@include s.mq(w767){
		top: 113px;
	}
	&:hover{
		opacity: 1;
		background: #444;
	}
}

//menu

.menu{
	position: fixed;
	width: 55px;
	height: 47px;
	top: 10px;
	right: 10px;
	// border-radius: 55px;
	// box-shadow: 0 0 9px 0px rgba(0,0,0,.15);
	cursor: pointer;
	z-index: 10000;
	//background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	&__border{
		display: block;
		width: 42px;
		height: 2px;
		background: #000;
		transition: all .2s;
	}
	&__border + &__border{
		margin-top: 6px;
	}
	&__txt{
		text-align: center;
		margin: 0;
		line-height: 1;
		margin-top: 2px;
	}
}

/*! purgecss start ignore */
.menu{
	&.active{
		.menu__border{
			background: #fff;
			&:first-child{
				transform: rotate(20deg) translateY(3.5px);
			}
			&:last-child{
				transform: rotate(-20deg) translateY(-5.5px);
			}
		}
	}
}

/*! purgecss end ignore */

.gnav{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background: #5a5c62;
	z-index: 1000;
	// display: flex;
	// justify-content: center;
	// align-items: center;
	&__list{
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-bottom: 40px;
	}
	&__item{
		&--reservation{
			margin-top: 2em;
		}
	}
	&__link{
		color: #fff;
		font-size: clamp(1.8rem, 3rem, 4rem);
		&--reservation{
			width: 150px;
			height: 34px;
			display: flex;
			justify-content: center;
			align-items: center;
			//background: #fff;
			border-radius: 17px;
			font-size: 1.4rem;
			color: #fff;
			margin: auto;
			background: #494a4f;
			// border: 1px solid #fff;
			transition: all .2s;
			&:hover{
				opacity: 1;
				background: #414247;
				color: #fff;
			}
		}
	}
	&__item + &__item{
		margin-top: 1em;
	}
}

.portrait{
	padding: 0 20px;
	width: 100%;
	max-width: 1210px;
	margin: 8vw auto 0 auto;
	.rollover {
		display: inline-block;
		position: relative;
		&:hover{
			opacity: 1;
		}
		.off {
		  position: relative;
		  top: 0;
		  left: 0;
		  z-index: 1;
		  transition: .2s ease;
		}
		.on {
		  position: absolute;
		  top: 0;
		  left: 0;
		  z-index: 0;
		}
		.on, .off {
		  display: block;
		}
	  }
	  .rollover:hover {
		.off {
		  opacity: 0;
		}
	  }
}

//access
.access{
	margin-top: 8vw;
	
	&__head{
		text-align: center;
	}
	&__map{
		margin-top: 1.2em;
		height: 0;
		overflow: hidden;
		padding-bottom: 46.25%;
		position: relative;
		iframe {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
		}
	}
	&__address{
		text-align: center;
		font-size: 1.4rem;
		line-height: 2;
		margin-top: 2em;
		@include s.mq(w767){
			padding: 0 20px;
		}
	}
}

.footer{
	background: #5a5c62;
	margin-top: 7em;
	padding: 40px 20px 28px 20px;
	position: relative;
	color: #fff;
	@include s.mq(w587){
		margin-top: 30vw;
	}
	&__head{
		width: 65px;
		margin: auto;
	}
	&__nav{
		margin-top: 2em;
		&-list{
			display: flex;
			justify-content: center;
			@include s.mq(w587){
				flex-wrap: wrap;
			}
		}
		&-item{
			@include s.mq(w587){
				margin-bottom: 10px;
			}
		}
		&-item + &-item{
			margin-left: 2.5em;
			@include s.mq(w587){
				margin-left: 1em;
			}
		}
		&-link{
			color: #fff;
			font-size: 1.4rem;
		}
	}
	&__reservation{
		margin-top: 2em;
		&-link{
			width: 150px;
			height: 34px;
			display: flex;
			justify-content: center;
			align-items: center;
			//background: #fff;
			border-radius: 17px;
			font-size: 1.4rem;
			color: #fff;
			margin: auto;
			background: #494a4f;
			// border: 1px solid #fff;
			transition: all .2s;
			&:hover{
				opacity: 1;
				background: #414247;
				color: #fff;
			}
		}
	}
	&__address{
		font-size: 1.3rem;
		text-align: center;
		line-height: calc(25 /13);
		margin-top: 2em;
	}
	&__copyright{
		font-size: 1.2rem;
		display: block;
		text-align: center;
		margin-top: 2em;
	}
	.pagetop{
		position: absolute;
		top: -44px;
		right: 87px;
		@include s.mq(w767){
			right: auto;
			left: 50%;
			transform: translateX(-50%);
		}
		&__link{
			font-size: 1rem;
			color: #000;
			text-transform: uppercase;
			&::before{
				content: url(../img/common/page-top.svg);
				display: block;
				text-align: center;
			}
		}
	}
}
.section{
	&__head{
		font-size: 4.5rem;
	}
}
.service{
	&__number{
		&-txt{
			text-transform: uppercase;
			font-size: 1rem;
			letter-spacing: 0.18em;
			&::after{
				content: '';
				display: inline-block;
				width: 1px;
				height: 54px;
				background: #000;
				transform: rotate(30deg) translateY(13px) translateX(20px);
				transform-origin: center;
				margin-right: 18px;
				@include s.mq(w900){
					height: 40px;
					transform: rotate(30deg) translateY(9px) translateX(16px);
				}
			}
			@include s.mq(w900){
				font-size: .8rem;
			}
		}
		&-id{
			font-family: linotype-didot, serif;
			font-weight: 400;
			font-style: italic;
			font-size: 3rem;
			display: inline-block;
			transform: translate(3px, 4px);
			@include s.mq(w900){
				font-size: 2.5rem;
			}
		}
		@include s.mq(w900){
			align-self: flex-start;
		}
	}
	&__head{
		font-size: 4.5rem;
		margin-top: 20px;
		@include s.mq(w1040){
			font-size: 4rem;
			margin-top: 10px;
		}
		@include s.mq(w900){
			margin-top: 0;
		}
		&--ja{
			font-size: 2.5rem;
			margin-left: 18px;
		}
	}
	&__txt{
		font-size: 1.4rem;
		line-height: 2;
		margin-top: 1em;
		@include s.mq(w1040){
			font-size: 1.3rem;
			line-height: 1.6;
		}
		@include s.mq(w900){
			width: 70%;
			line-height: 1.8;
		}
		@include s.mq(w767){
			width: 90%;
		}
		@include s.mq(w587){
			width: 100%;
		}
	}
}

.page{
	// &::after{
	// 	content: '';
	// 	display: block;
	// 	clear: both;
	// 	height: 0;
	// 	visibility: hidden;
	// }
	.kv{
		img{
			@include s.mq(w587){
				height: 50vw;
				object-fit: cover;
			}
		}
	}
	.service{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 2vw;
		// gap: 40px;
		@include s.mq(w900){
			width: 80%;
			padding: 0 20px;
			margin-left: auto;
			margin-right: auto;
			display: block;
		}
		&__number{
			transform: translateY(-5px);
			margin-right: 40px;
		}
		&__txt{
			margin-top: .5em;
			@include s.mq(w900){
				width: 100%;
			}
		}
	}
	.plan{
		width: 85vw;
		display: flex;
		justify-content: space-between;
		clear: both;
		@include s.mq(w980){
			flex-direction: column;
		}
		.section__head{
			.ja{
				font-size: 1.2rem;
				display: block;
				margin-top: 5px;
			}
		}
	
		&__txt{
			width: 22vw;
			@include s.mq(w1080){
				width: 25vw;
			}
			@include s.mq(w980){
				width: 100%;
				margin-top: 1em;
			}
		}
		&__img{
			width: 55vw;
			@include s.mq(w980){
				width: 100%;
				order: -1;
			}
		}
		&--l{
			float: left;
			.plan__img{
				order: -1;
			}
			.plan__txt{
				@include s.mq(w980){
					padding-left: 20px;
				}
			}
		}
		&--r{
			float: right;
			.plan__img{
				@include s.mq(w980){
					order: -1;
				}
			}
			.plan__txt{
				@include s.mq(w980){
					padding-right: 20px;
				}
			}
		}
		&__list{
			margin-top: 2em;
			width: 100%;
			@include s.mq(w980){
				margin-top: 1em;
			}
		}
		th,td{
			padding: 13px 0;
			border-bottom: 1px solid #cbcbcb;
			font-weight: normal;
			line-height: 1.4;
		}
		th{
			font-size: 1.4rem;
			padding-right: 1em;
		}
		td{
			text-align: right;
			font-size: 1.8rem;
			white-space: nowrap;
		}
	}
	.plan + .plan{
		margin-top: 10vw;
	}
	.contact,
	.more{
		text-align: center;
	}
	.more{
		margin-top: 3em;
	}
	.btn{
		display: inline-block;
		border: 1px solid #000;
		padding: 22px 75px;
		font-size: 2rem;
		color: #000;
		// font-weight: 700;
		&--more{
			padding: 10px 57px;
			font-size: 1.8rem;
		}
	}
	.insta{
		display: flex;
		justify-content: center;
		margin-top: 7vw;
		&__link{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		&__icon{
			width: 60px;
			height: auto;
		}
		&__txt{
			color: #000;
			margin-top: 10px;
			font-size: 2.4rem;
		}
	}
	.access{
		@include s.mq(w767){
			margin-top: 20vw;
		}
	}
}

// gallery

.gallery{
	width: 88vw;
	margin: 10vw auto 0 auto;
	.section__head{
		text-align: center;
	}
	&__list{
		margin: 5.17vw auto 0 auto;
	}
	&__cap{
		font-size: 1.3rem;
		line-height: calc(23 / 13);
		margin-top: 10px;
		@include s.mq(w767){
			font-size: 1.2rem;
			line-height: 1.4;
			text-align: left;
			margin-top: 7px;
		}
	}
	&__img{
		width: 27.02vw;
		margin-bottom: 30px;
		@include s.mq(w767){
			width: 45%;
		}
	}
}

.artist{
	margin: 10vw 0 6vw 0;
	@include s.mq(w767){
		margin-bottom: 10vw;
	}
	&__prof{
		width: 100%;
		max-width: 760px;
		margin: auto;
		@include s.mq(w767){
			padding: 0 20px;
		}
	}
	&__head{
		.ja{
			font-size: 1.2rem;
		}
		.en{
			font-size: 5rem;
			display: block;
			margin-top: .7rem;
			@include s.mq(w767){
				font-size: 3.5rem;
			}
		}
	}
}

.cat{
	.gallery{
		margin-top: 5vw;
	}
	.artist{
		margin-top: 2vw;
		&__prof{
			max-width: 88vw;
		}
	}
}
/*! purgecss start ignore */


.single{
	.artist{
		margin-top: 2vw;
		&__prof{
			max-width: 88vw;
		}
	}
	.gallery{
		width: 100vw;
		margin-top: 2.17vw;
		overflow-x: hidden;
		&__cap{
			text-align: center;
			font-size: 1.6rem;
		}
		&__slide{
			margin-top: 2em;
		}
		img{
			width: auto;
			height: 55.38vw;
			object-fit: cover;
			@include s.mq(w767){
				height: 40vh;
			}
		}
		// .btn{
		// 	@include s.mq(w767){
		// 		padding-top: 12px;
		// 		padding-bottom: 12px;
		// 	}
		// }
		figcaption{
			font-size: 1.2rem;
			line-height: 1.5;
		}
		.slick-list{
			padding: 0 !important;
		}
		.slick-prev, .slick-next{
			top: calc(55.38vw / 2 - 16px);
			transform: translateY(0);
			width: 32px;
			height: 32px;
			@include s.mq(w767){
				top: calc(40vh / 2 - 16px);
			}
		}
		.slick-prev{
			right: auto;
			left: 20px;
		}
		.slick-next{
			left: auto;
			right: 20px;
		}
		.slick-prev:before, .slick-next:before{
			font-size: 22px;
		}
		.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before{
			opacity: .50;
		}
	}
}

.more{
	&__btn{
		width: 200px;
		height: 44px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.6rem;
		letter-spacing: .3em;
		color: #000;
		border: 1px solid #000;
		transition: all .2s;
		margin: 3em auto 0 auto;
		&:hover{
			opacity: 1;
			background: #000;
			color: #fff;
		}
		&--ja{
			letter-spacing: .1em;
			font-size: 1.5rem;	
		}
		&--contact{
			width: 300px;
			height: 60px;
		}
	}
}

/*! purgecss end ignore */


/*! purgecss start ignore */



/*! purgecss end ignore */


