@charset "UTF-8";
@use "_reset";
@use "settings" as s;
@use "user_reset";
@use "common";
@use "animation";
@use "slick";
@use "slick-theme";


//kv
.kv{
	// display: flex;
	// justify-content: flex-end;
	// position: relative;
	// &__catch{
	// 	position: absolute;
	// 	top: 40%;
	// 	left: 5%;
	// 	transform: rotate(-5deg);
	// 	font-family: allura, sans-serif;
	// 	font-weight: 400;
	// 	font-style: normal;
	// 	font-size: 8.5rem;
	// 	z-index: 1;
	// }
	padding: 0 20px;
	&__slide{
		width: 100%;
		//max-width: 80vw;
	}
	
}

//concept
.concept{
	width: 84vw;
	margin: 10vh auto 0 auto;
	//padding: 0 8vw;
	position: relative;
	@include s.mq(w767){
		width: 100%;
		padding: 0 20px;
		//height: 235.7vw;
	}
	&::after{
		content: '';
		display: block;
		clear: both;
		@include s.mq(w767){
			width: 64.53vw;
			height: 128.4vw;
			background: #f7f7f7;
			position: absolute;
			bottom: -6vw;
			right: 0;
			z-index: -1;
		}
	}
	&__img{
		&--01{
			width: 37.7vw;
			float: left;
			transform: translateY(10%);
			@include s.mq(w1040){
				float: none;
			}
			@include s.mq(w767){
				width: 66.13vw;
				transform: translateY(0);
			}
		}
		&--02{
			width: 30vw;
			position: absolute;
			top: 0;
			left: calc(37.7vw + 40px);
			@include s.mq(w767){
				width: 52.26vw;
				top: 59.46vw;
				right: 20px;
				left: auto;
			}
		}
	}
	&__txt{
		//width: 40%;
		position: absolute;
		top: 21vw;
		right: 0;
		background: #f7f7f7;
		width: 56vw;
		min-height: 40vw;
		padding: 12vw 3vw 20px 12.5vw;
		z-index: -1;
		@include s.mq(w1040){
			position: static;
			min-height: auto;
			padding: 20px;
			transform: translate(220px, 10px);
		}
		@include s.mq(w767){
			//width: 80vw;
			width: 100%;
			padding: 0;
			transform: translate(0);
			margin-top: 24.33vw;
			background: none;
		}
	}
	&__head{
		font-size: 4.5rem;
	}
	&__body{
		font-size: 1.4rem;
		line-height: calc(30 / 14);
		margin-top: 2em;
		@include s.mq(w1040){
			margin-top: 1em;
		}
	}
}

//service

.service{
	margin-top: 17vw;
	&__container{
		position: relative;
		// background-position: 50% 50%;
		// background-size: cover;
		// background-repeat: no-repeat;
		height: 33.6vw;
		display: flex;
		align-items: center;
		padding: 0 4vw;
		&--r{
			justify-content: flex-end;
		}
		@include s.mq(w900){
			justify-content: center;
			flex-direction: column;
			height: auto;
			padding: 0;
		}
	}
	&__container + &__container{
		@include s.mq(w900){
			margin-top: 30px;
		}
	}
	&__img{
		width: 100%;
		height: 33.6vw;
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		@include s.mq(w900){
			position: static;
		}
		@include s.mq(w587){
			height: 53.6vw;
		}
		img{
			object-fit: cover;
			@include s.mq(w587){
				height: 53.6vw;
			}
		}
		&--portrait{
			&::after{
				content: '';
				display: block;
				width: 100%;
				height: 33.6vw;
				background: rgba(0, 0, 0, .17);
				position: absolute;
				top: 0;
				left: 0;
				@include s.mq(w587){
					height: 53.6vw;
				}
			}
		}
	}
	&__link{
		color: #000;
		display: block;
	}
	&__contents{
		width: 35vw;
		transform: translateY(-15px);
		&--artist{
			color: #fff;
			@include s.mq(w900){
				color: #000;
			}
		}
		@include s.mq(w1040){
			width: 40vw;
		}
		@include s.mq(w900){
			width: 80vw;
			background: #fff;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			transform: translateY(-30px);
			padding: 0 30px 20px 30px;
		}
	}
	&__more{
		&-btn{
			display: block;
			background: rgba(0,0,0,.4);
			width: 160px;
			height: 40px;
			border-radius: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.6rem;
			color: #fff;
			margin-top: 20px;
			@include s.mq(w900){
				background: #000;
			}
		}
	}
	// &__salon{
	// 	background-image: url(../img/home/salon-img@2x.jpg);
	// }
	&__portrait{
		// background-image: linear-gradient(rgba(0, 0, 0, .17), rgba(0, 0, 0, .17)), url(../img/home/portrait-img@2x.jpg);
		color: #fff;
		@include s.mq(w900){
			color: #000;
		}
		.service__link{
			color: #fff;
		}
		.service__number-txt{
			&::after{
				background: #fff;
				@include s.mq(w900){
					background: #000;
				}
			}
		}
		.service__txt{
			br:nth-of-type(3){
				display: none;
			}
		}
	}
	&__artist{
		//background: #F2F2F2;
		background: url(../img/home/artist-bg@2x.jpg) no-repeat 50%;
		background-size: cover;
		justify-content: space-between;
		@include s.mq(w900){
			background: none;
		}
		.service__number-txt{
			&::after{
				background: #fff;
				@include s.mq(w900){
					background: #000;
				}
			}
		}
		&-logo{
			//order: -1;
			width: 60vw;
			display: flex;
			justify-content: center;
			align-items: center;
			img{
				width: 19.4vw;
			}
			@include s.mq(w900){
				order: -1;
				width: 100%;
				height: 33.6vw;
				background: #F2F2F2;
			}
			@include s.mq(w587){
				height: 53.6vw;
			}
		}
		// .service__more-btn{
		// 	background: #000;
		// }
	}
	&__model{
		//background: #F2F2F2;
		justify-content: space-between;
		border: 5px solid #001f79;
		@include s.mq(w900){
			border: none;
		}
		&-logo{
			order: -1;
			width: 60vw;
			display: flex;
			justify-content: center;
			align-items: center;
			img{
				width: 12.7vw;
				//mix-blend-mode: multiply;
				@include s.mq(w900){
					mix-blend-mode: multiply;
				}
			}
			@include s.mq(w900){
				order: -1;
				width: 100%;
				height: 33.6vw;
				// border: 5px solid #001f79;
				background: #F2F2F2;
			}
			@include s.mq(w587){
				height: 53.6vw;
			}
		}
		.service__more-btn{
			background: #000;
		}
	}
}

.section{
	&__head{
		text-align: center;
	}
}

//blog
.blog{
	padding: 0 20px;
	width: 100%;
	max-width: 1210px;
	margin: 8vw auto 0 auto;
	&__container{
		display: grid;
		gap: 80px 50px;
		//grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		margin-top: 3em;
		@include s.mq(w900){
			gap: 30px;
			//grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		}
		@include s.mq(w587){
			gap: 30px;
			grid-template-columns: 1fr 1fr;
		}
	}
	&__article{
		// width: calc(100% / 3 - 60px);
		display: flex;
		flex-direction: column;
	}
	&__img{
		order: -1;
	}
	&__title{
		font-size: 1.4rem;
		text-align: center;
		margin-top: 2.2em;
		line-height: 1.5;
		@include s.mq(w587){
			margin-top: 1.2em;
		}
	}
	&__content{
		margin-top: 1.6em;
		@include s.mq(w587){
			margin-top: 1em;
		}
		p{
			font-size: 1.4rem;	
			line-height: calc(25 / 14);
		}
	}
	&__date{
		font-size: 1.2rem;
		color: #555;
		margin-top: 2em;
		background: url(../img/common/blog-calender.svg) no-repeat;
		padding-left: 16px;
	}
	.more__btn{
		margin-top: 8vw;
	}
}

//insta
.insta{
	margin-top: 8vw;
	@include s.mq(w900){
		margin-top: 12vw;
	}
	@include s.mq(w587){
		margin-top: 20vw;
	}
	&__container{
		margin-top: 2em;
		@include s.mq(w587){
			margin-top: 0;
		}
	}
}

