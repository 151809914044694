/*! purgecss start ignore */

.anime-n{
	animation: animeno 1s ease .5s 1 normal forwards running;
	opacity: 0;
}
@keyframes animeno {
	0%{opacity:0;}
	100%{opacity:1;}
  }
.anime{
	opacity: 0;
	transform: translateY(-10px) scale(0.99,0.99);
	//transition: all 1.5s;
	transition: 1s;
	
}
.move{
   // animation:sampleAnime 1s alternate;
    //transition: 200ms;
    opacity: 1;
	transform: translateY(0) scale(1, 1);
}
.anime-r{
	overflow: hidden;
	opacity: 0;
	transform: translateX(20px);
	transition: all 500ms;
}
.move-r{
	overflow: hidden;
   // animation:sampleAnime 1s alternate;
    //transition: 200ms;
    opacity: 1;
	transform: translateX(0);
}
.anime-l{
	overflow: hidden;
	opacity: 0;
	transform: translateX(-20px);
	transition: all 500ms;
}
.move-l{
	overflow: hidden;
   // animation:sampleAnime 1s alternate;
    //transition: 200ms;
    opacity: 1;
	transform: translateX(0);
}

.anime-d{
	opacity: 0;
	transform: translatedY(-50px);
	transition: 1s;
	transition-delay: 0.5s;
}
.move-d{
   // animation:sampleAnime 1s alternate;
    //transition: 200ms;
    opacity: 1;
	transform: translateY(0);
}

.anime-b{
	opacity: 0;
	transform: translatedY(50px);
	transition: 1s;
	//transition-delay: 0.5s;
}
.move-b{
   // animation:sampleAnime 1s alternate;
    //transition: 200ms;
    opacity: 1;
	transform: translateY(0);
}
/*! purgecss end ignore */